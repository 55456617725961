function Tag({ tag }) {
  return (
    <div className="inline">
      <span className="px-1 bg-coffee text-white whitespace-nowrap mr-2">
        {tag}
      </span>
    </div>
  );
}

export default Tag;
