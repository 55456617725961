import Subheader from "./Subheader";
import Experience from "./Experience";

function WorkSection() {
  return (
    <>
      <Subheader name="Experience" />
      <div className="flex justify-between xl:flex-col">
        <Experience
          company={"Concur"}
          position={"Software Engineer Intern"}
          startDate={"MAY 2023"}
          endDate={"NOVEMBER 2023"}
          link={"https://www.concur.com/"}
          tags={["React", "Redux", "GraphQL", "AWS", "CloudFormation"]}
        />
        <Experience
          company={"SAP SE"}
          position={"Software Engineer Intern"}
          startDate={"JULY 2022"}
          endDate={"JANUARY 2023"}
          link={"https://www.sap.com/index.html"}
          tags={["ABAP", "SAP GUI", "ABAP Dev. Tools"]}
        />
        <Experience
          company={"TechBerry"}
          position={"QA Tester Intern"}
          startDate={"JANUARY 2022"}
          endDate={"FEBRUARY 2022"}
          link={"https://web.techberry.tech/"}
          tags={["Java", "Go", "Low-code Platforms"]}
        />
      </div>
    </>
  );
}

export default WorkSection;
