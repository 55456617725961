function Logo() {
  return (
    <div>
      <h1 className="transition ease-in-out text-coffee text-4xl font-black pb-1 px-3 py-2 hover:bg-coffee hover:text-white lg:text-2xl">
        KEVIN/LEEY
      </h1>
    </div>
  );
}

export default Logo;
