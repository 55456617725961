import Subheader from "./Subheader";
import WorkSection from "./WorkSection";
import Project from "./Project";
import Education from "./Education";

function Main() {
  return (
    <div className="max-w-7xl w-5/6 2xl:w-full">
      <div className="mb-12">
        <Subheader name="Education" />
        <div className="flex gap-52 xl:gap-12 xl:flex-col">
          <Education
            startDate={"AUGUST 2019"}
            endDate={"MAY 2024"}
            degree={"Bachelor's of Science in Computer Science"}
            grade={"3.76"}
            institution={"The University of Alabama"}
            link={"https://www.ua.edu/"}
          />
          <Education
            startDate={"MARCH 2022"}
            endDate={"JULY 2022"}
            degree={"Exchange Semester in Informatik"}
            institution={"Esslingen University of Applied Sciences"}
            link={"https://www.hs-esslingen.de/en"}
            grade={"1,3"}
          />
        </div>
      </div>
      <div className="mb-12">
        <WorkSection />
      </div>
      <div>
        <Subheader name="Projects" />
        <div className="flex justify-between gap-52 xl:gap-12 xl:flex-col">
          <Project
            name={"SeniorForum"}
            startDate={"JANUARY 2024"}
            endDate={"MAY 2024"}
            link={"https://github.com/kevinleey/SeniorForum"}
            description={
              "SeniorForum is a discussion forum built using React and Redux tailored for the senior community of The University of Alabama."
            }
            position="Project Lead"
          />
          <Project
            name={"Q-CV"}
            startDate={"MARCH 2022"}
            endDate={"JULY 2022"}
            link={"https://github.com/FreakeyPlays/q-cv"}
            description={
              "Q-CV is a resume generator web application that automatically generates a fully stylized resume in PDF form based off user data."
            }
            position="Project Collaborator"
          />
        </div>
      </div>
    </div>
  );
}

export default Main;
