import exportPhoto from "../export.png";
import Tag from "./Tag";

function Experience({ company, position, startDate, endDate, link, tags }) {
  return (
    <div className="h-40 w-60 flex flex-col justify-between overflow-hidden xl:mb-12">
      <div>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <p className="inline font-bold underline">{company}</p>
          <img className="inline w-3 h-3 ml-2" src={exportPhoto} alt="export" />
        </a>
        <p className="italic">{position}</p>
        <p className="mt-3">
          {startDate}-{endDate}
        </p>
      </div>
      <div className="ticker min-w-max">
        <ul>
          {tags?.map((tag) => (
            <li className="inline">
              <Tag tag={tag} />
            </li>
          ))}
        </ul>
        <ul>
          {tags?.map((tag) => (
            <li className="inline">
              <Tag tag={tag} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Experience;
