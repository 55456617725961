import heroPhoto from "../hero.png";
import handPhoto from "../hand.png";
import exportPhoto from "../export.png";
import { useParallax } from "react-scroll-parallax";

function Hero() {
  const { ref } = useParallax({ speed: 20 });

  return (
    <div ref={ref} className="flex justify-between mt-16 gap-32 max-w-7xl min-h-96 border-0 border-amber-300 lg:flex-col-reverse lg:gap-6 lg:mt-6">
      <div className="flex flex-col justify-between">
        <div className="pt-6">
          <h1 className="text-5xl text-coffee lg:text-3xl">
            navamin leelarburanathanakoon
          </h1>
          <h2 className="text-3xl lg:text-2xl">/ˈnɑvəmɪn'/ </h2>
          <div className="mt-8 lg:mt-4">
            <img className="inline w-10 pb-2 pr-2" src={handPhoto} alt="hand" />
            <p className="inline text-3xl lg:text-xl">
              I'm Man, a full-stack developer based in Bangkok, Thailand.
            </p>
          </div>
        </div>
        <div className="lg:mt-10">
          <a
            href="https://drive.google.com/file/d/1OWVmW07e5Z06d4xhwMlpTnokpw2IavvQ/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className="inline text-2xl lg:text-xl">See my resume!</p>
            <img
              className="inline w-4 ml-3 mb-3 mt-1"
              src={exportPhoto}
              alt="write"
            />
          </a>
        </div>
      </div>
      <img className="w-1/4 h-1/4 lg:w-full" src={heroPhoto} alt="itsme!" />
    </div>
  );
}

export default Hero;
