import exportPhoto from "../export.png";

function Education({ institution, degree, grade, startDate, endDate, link }) {
  return (
    <div className="w-2/3 xl:w-full">
      <a href={link} target="_blank" rel="noopener noreferrer">
        <p className="inline font-bold underline">{institution}</p>
        <img className="inline w-3 h-3 ml-2" src={exportPhoto} alt="export" />
      </a>
      <p>
        {degree} | {grade}
      </p>
      <p className="mt-3">
        {startDate}-{endDate}
      </p>
    </div>
  );
}

export default Education;
