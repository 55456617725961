import Logo from "./Logo";
import Nav from "./Nav";

function Header() {
  return (
    <div>
      <header className="sticky top-0 z-[20] mx-auto flex w-full items-center justify-between px-16 py-8 lg:px-3 lg:py-2 lg:gap-6">
        <Logo />
        <Nav />
      </header>
    </div>
  );
}

export default Header;
