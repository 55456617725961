function NavLinks() {
  return (
    <>
      <a
        href={"https://linkedin.com/in/kevinleey"}
        target="_blank"
        rel="noopener noreferrer"
        className="hover-lift lg:text-sm"
      >
        linkedin
      </a>
      <a
        href={"https://github.com/kevinleey/"}
        target="_blank"
        rel="noopener noreferrer"
        className="hover-lift lg:text-sm"
      >
        github
      </a>
      <a
        href={"https://www.instagram.com/kevin.leelr/"}
        target="_blank"
        rel="noopener noreferrer"
        className="hover-lift lg:text-sm"
      >
        instagram
      </a>
    </>
  );
}

function Nav() {
  return (
    <nav className="w-80">
      <div className="flex justify-between">
        <NavLinks />
      </div>
    </nav>
  );
}

export default Nav;
