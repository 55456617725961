import Header from "./components/Header";
import Hero from "./components/Hero";
import Main from "./components/Main";
import { ParallaxProvider } from "react-scroll-parallax";
import Footer from "./components/Footer";

function App() {
  return (
    <ParallaxProvider>
      <div className="min-h-screen bg-skyline bg-cover bg-center font-fustat">
        <Header />
        <div className="w-7/12 mx-auto lg:w-3/4">
          <Hero />
          <Main />
        </div>
        <Footer />
      </div>
    </ParallaxProvider>
  );
}

export default App;
