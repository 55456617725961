import starPhoto from "../star.png";

function Subheader({ name }) {
  return (
    <div className="flex group text-black transition-all duration-300 ease-in-out pb-10">
      <img className="w-8 h-8 inline mr-2" src={starPhoto} alt="star" />
      <span className="text-2xl bg-left-bottom bg-gradient-to-r from-black to-black bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out">
        {name}
      </span>
    </div>
  );
}

export default Subheader;
