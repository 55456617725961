import exportPhoto from "../export.png";

function Project({ name, description, startDate, endDate, link, position }) {
  return (
    <div className="w-2/3 xl:w-full">
      <div className="flex justify-between mb-2">
        <a href={link} target="_blank" rel="noopener noreferrer">
          <p className="inline font-bold underline">{name}</p>
          <img className="inline w-3 h-3 ml-2" src={exportPhoto} alt="export" />
        </a>
        <p className="italic">{position}</p>
      </div>
      <p>{description}</p>
      <p className="mt-3">
        {startDate}-{endDate}
      </p>
    </div>
  );
}

export default Project;
